<template>
  <div class="owner-patients-information-change">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="page-title">
        <base-page-title>{{
          $t('ownerPatientsInformationChange.pageTitle')
        }}</base-page-title>
      </div>
      <div class="owner-patients-progress-bar">
        <progress-bar v-bind="progressBarData" />
      </div>
      <div class="owner-patients-input-form">
        <input-table-change-owner
          ref="inputTableChangeOwner"
          :owner="owner"
          :patients="patients"
          :apiResultPostalCode="mixinApiResultPostalCode"
          :usedMailMessage="mixinUsedMailMessage"
          :existsMedicalRecordInfo="existsMedicalRecordInfo"
          @add-patient="addPatient"
          @del-patient="delPatient"
          @input-postal-code="inputPostalCode"
          @auto-input-address="mixinInputAddress('owner')"
          @keyup="mixinKeyupIsOkEmail($event, owner.email)"
        />
      </div>
      <div class="next-button">
        <base-decision-button
          :disabled="invalid || mixinIsNgEmail"
          @validate="isValid"
          @click="pushToOwnerPatientsInformationConfirm"
          >{{ $t('common.buttonNext') }}</base-decision-button
        >
      </div>
      <div class="back-button">
        <base-cancel-button @click="backOwnerPatientsInformation">{{
          $t('common.buttonReturn')
        }}</base-cancel-button>
      </div>
      <unsaved-leave-popup @cancel="fixRouteHistory" />
    </validation-observer>
    <announce-popup
      v-if="alertFlg"
      :type="'failure'"
      :title="$t('common.error')"
      :buttons="buttons.map(v => $t(v))"
      @close="alertFlg = false"
      >{{ $t(popupMessage) }}</announce-popup
    >
  </div>
</template>

<script>
import store from '@/store/store'
window.addEventListener('popstate', () => {
  if (location.href.includes('owner-patients-information-confirm')) {
    store.dispatch('petorelu/okLeave')
  }
})
import { ValidationObserver } from 'vee-validate'
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import InputTableChangeOwner from '@/components/parts/organisms/InputTableChangeOwner.vue'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton.vue'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton.vue'
import ReloadConfirm from '@/components/mixins/ReloadConfirm'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import IsOkEmail from '@/components/mixins/IsOkEmail'
import AutoInputPrefecture from '@/components/mixins/AutoInputPrefecture'
import { mapGetters } from 'vuex'
import { usedMailMessage } from '@/utils/validation_rules'
import _ from 'lodash'
import { getAxiosObject } from '@/utils/library'

export default {
  name: 'OwnerPatientsInformationChange',

  components: {
    BasePageTitle,
    ProgressBar,
    InputTableChangeOwner,
    BaseDecisionButton,
    BaseCancelButton,
    ValidationObserver,
    UnsavedLeavePopup,
    AnnouncePopup
  },

  mixins: [ReloadConfirm, IsOkEmail, AutoInputPrefecture],

  data() {
    return {
      barWidthPc: 'owner-info-edit-screen',
      progressBarData: {
        textList: [
          'common.progressBarModifyOwner',
          'common.progressBarConfirm',
          'common.progressBarFinish'
        ],
        textOrangeNumber: 1
      },
      owner: _.cloneDeep(this.$store.getters['owner/getChangeOwner']),
      patients: [],
      delPatientsIds: [],
      initialOwner: _.cloneDeep(this.$store.getters['owner/getChangeOwner']),
      initialPatients: [],
      alertFlg: false,
      popupMessage: '',
      buttons: ['common.close'],
      key: 1,
      existsMedicalRecordInfo: []
    }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById',
      clinic: 'clinic/getClinic'
    })
  },

  watch: {
    owner: {
      handler: function() {
        this.compareData()
        this.setStore()
      },
      deep: true
    },
    patients: {
      handler: function() {
        this.compareData()
        this.setStore()
      },
      deep: true
    }
  },

  created() {
    if (this.clinic.karteFlg) {
      this.fetchExistsMedicalRecordInfo()
    }
    this.patients = this.$store.getters['patient/getChangePatients'].map(p => {
      return { ...p, initialSpeciesId: p.speciesId }
    })
    this.initialPatients = this.$store.getters['patient/getPatients']
    this.key = this.patients.length
  },

  methods: {
    async fetchExistsMedicalRecordInfo() {
      try {
        const axiosObject = getAxiosObject()
        const res = await axiosObject.get('/owner-patients/medical-records')
        this.existsMedicalRecordInfo = res.data.existsMedicalRecordInfo
      } catch (ex) {
        this.alertFlg = true
        this.popupMessage =
          'ownerPatientsInformationChange.popupMessageSeverError'
      }
    },
    isValid(invalidFlg) {
      this.$store.dispatch('petorelu/invalidFlg', invalidFlg)
    },
    compareData() {
      const patients = this.patients.map(p => {
        return {
          id: p.id,
          breed: p.breed,
          name: p.name,
          speciesId: p.speciesId
        }
      })
      const isEqual =
        _.isEqual(this.owner, this.initialOwner) &&
        _.isEqual(_.sortBy(patients), _.sortBy(this.initialPatients))
      this.toggleLeaveFlg(isEqual)
    },
    toggleLeaveFlg(isEqual) {
      isEqual
        ? this.$store.dispatch('petorelu/okLeave')
        : this.$store.dispatch('petorelu/ngLeave')
    },
    addPatient() {
      this.key += 1
      const patient = { name: '', speciesId: '', breed: '', key: this.key }
      this.patients.push(patient)
    },
    delPatient(obj) {
      if (obj.value.id) {
        this.delPatientsIds.push({ delPatientId: obj.value.id })
      }
      this.patients.splice(obj.index, 1)
    },
    inputPostalCode(val) {
      if (
        !this.mixinApiResultPostalCode?.existingPostalCodeFlg &&
        val.length !== 7
      ) {
        this.mixinApiResultPostalCode = null
      }
      this.owner.postalCode = val
    },
    setStore() {
      this.$store.dispatch('owner/inputChangeOwner', this.owner)
      this.$store.dispatch('patient/inputChangePatients', {
        patients: this.patients,
        delPatientsIds: this.delPatientsIds
      })
    },
    async pushToOwnerPatientsInformationConfirm() {
      const result = await this.mixinFetchIsOkEmail(this.owner.email)
      if (result?.uniqueEmail) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: 'OwnerPatientsInformationConfirm' })
      } else if (result?.uniqueEmail === false) {
        this.mixinIsNgEmail = true
        this.mixinUsedMailMessage = usedMailMessage
        this.focusMailTextBox()
      } else if (result === 'not authenticated') {
        this.$store.dispatch('petorelu/okLeave')
        this.buttons = ['common.buttonGoToLogin']
        this.alertFlg = true
      } else {
        this.popupMessage =
          'ownerPatientsInformationChange.popupMessageTryAgain'
        this.alertFlg = true
      }
    },
    focusMailTextBox() {
      // 下記の処理は$refsを二段階以上で使用していてモック化できなかったため、テストのため関数にしてモック化できるようにしました。
      this.$refs.inputTableChangeOwner.$refs.baseWideTextBox.$refs.wideTextBox.focus()
    },
    backOwnerPatientsInformation() {
      this.$router.back()
    },
    fixRouteHistory() {
      if (location.href.includes('owner-patients-information-confirm')) {
        this.$router.back()
      } else if (!location.href.includes('owner-patients-information-change')) {
        this.$router.go(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-patients-information-change {
  margin-bottom: 100px;
  > .observer {
    > .page-title {
      margin-top: 70px;
    }
    > .owner-patients-progress-bar {
      margin-top: 28px;
    }
    > .owner-patients-input-form {
      margin-top: 75px;
    }
    > .next-button {
      margin-top: 80px;
    }
    > .back-button {
      margin-top: 23px;
    }
  }
}
@media (max-width: $global-media-width) {
  .owner-patients-information-change {
    > .observer {
      > .owner-patients-input-form {
        margin-top: 54px;
      }
      > .next-button {
        margin-top: 60px;
      }
    }
  }
}
</style>
