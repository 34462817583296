<template>
  <div class="input-table-change-owner">
    <table>
      <tr>
        <th>
          {{ $t('common.labelName')
          }}{{ $t('common.labelJaBrackets', { str: '全角' }) }}
          <base-required-mark />
        </th>
        <td>
          <full-name-input-form :owner="owner" data-test="full-name" />
        </td>
      </tr>
      <tr>
        <th>
          {{ $t('common.labelNameKana')
          }}{{ $t('common.labelJaBrackets', { str: 'カナ' }) }}
          <base-required-mark />
        </th>
        <td>
          <full-name-kana-input-form :owner="owner" />
        </td>
      </tr>
      <tr>
        <th class="patient-th">
          <div class="attention-under-960-width">
            {{ attentionMessage }}
          </div>
          <span class="label">{{ $t('common.labelPetName') }}</span
          ><base-required-mark /><br />
          <div class="attention">
            {{ attentionMessage }}
          </div>
        </th>
        <td>
          <patient-data-input-form
            v-for="(patient, i) in patients"
            :key="patient.key"
            :patient="patient"
            :index="i"
            :isIncludesPatientIdInReservations="
              isIncludesPatientIdInReservations(patient)
            "
            :existsMedicalRecordInfo="existsMedicalRecordInfo"
            @del-patient="delPatient"
          />
          <base-button-plus
            :text="$t('common.buttonAddPatient')"
            @click="addPatient"
          />
        </td>
      </tr>
      <tr>
        <th>
          {{ $t('common.labelPhoneNumber')
          }}{{ $t('common.labelJaBrackets', { str: '半角数字' }) }}
          <base-required-mark />
        </th>
        <td>
          <validation-provider
            rules="requiredRule|regexTel"
            v-slot="{ errors }"
          >
            <base-wide-text-box
              :type="'tel'"
              :placeholder="'08012345678'"
              :maxlength="11"
              v-model="owner.tel"
            />
            <div class="error" data-test="tel-err">{{ $t(errors[0]) }}</div>
          </validation-provider>
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelEmail') }} <base-required-mark /></th>
        <td>
          <validation-provider
            rules="requiredRule|emailRule"
            v-slot="{ errors }"
          >
            <base-wide-text-box
              ref="baseWideTextBox"
              :name="'email'"
              :type="'email'"
              :placeholder="'test@mail.com'"
              :maxlength="254"
              v-model="owner.email"
              @keyup="$emit('keyup', errors)"
            />
            <div class="error" data-test="email-err">
              {{ errors[0] ? $t(errors[0]) : $t(usedMailMessage) }}
            </div>
          </validation-provider>
        </td>
      </tr>
      <tr>
        <th>
          {{ $t('common.labelPostalCode')
          }}{{ $t('common.labelJaBrackets', { str: '半角数字' }) }}
        </th>
        <td>
          <auto-input-form
            :validationRules="{
              regexPostalCode: true,
              apiResultPostalCode: apiResultPostalCode
            }"
            :immediate="true"
            :placeholder="$t('common.placeholderPostalCode')"
            :maxlength="7"
            :inputStyle="{ width: '120px' }"
            :buttonText="$t('common.buttonAutoFillAddress')"
            :value="owner.postalCode"
            @input="$emit('input-postal-code', $event)"
            @click="$emit('auto-input-address')"
          />
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelPrefecture') }}</th>
        <td class="prefectures">
          <base-select-box
            :selectData="prefecturesSelect"
            v-model.number="owner.prefectureId"
          />
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelCityTownVillage') }}</th>
        <td>
          <validation-provider
            :rules="{ notSurrogatePair: true }"
            v-slot="{ errors }"
          >
            <base-wide-text-box
              :placeholder="
                $t('common.placeholderStringLength', { length: '50' })
              "
              :maxlength="50"
              v-model="owner.address"
            />
            <div class="error">{{ $t(errors[0]) }}</div>
          </validation-provider>
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelBuildingApartment') }}</th>
        <td>
          <validation-provider
            :rules="{ notSurrogatePair: true }"
            v-slot="{ errors }"
          >
            <base-wide-text-box
              :placeholder="
                $t('common.placeholderStringLength', { length: '50' })
              "
              :maxlength="50"
              v-model="owner.building"
            />
            <div class="error">{{ $t(errors[0]) }}</div>
          </validation-provider>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import BaseRequiredMark from '@/components/parts/atoms/BaseRequiredMark.vue'
import FullNameInputForm from '@/components/parts/molecules/FullNameInputForm.vue'
import FullNameKanaInputForm from '@/components/parts/molecules/FullNameKanaInputForm.vue'
import PatientDataInputForm from '@/components/parts/molecules/PatientDataInputForm.vue'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus.vue'
import BaseWideTextBox from '@/components/parts/atoms/BaseWideTextBox.vue'
import AutoInputForm from '@/components/parts/molecules/AutoInputForm'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'InputTableChangeOwner',

  components: {
    ValidationProvider,
    BaseRequiredMark,
    FullNameInputForm,
    FullNameKanaInputForm,
    PatientDataInputForm,
    BaseButtonPlus,
    BaseWideTextBox,
    BaseSelectBox,
    AutoInputForm
  },

  props: {
    owner: {
      firstName: { type: String },
      lastName: { type: String },
      firstNameKana: { type: String },
      lastNameKana: { type: String },
      tel: { type: String },
      email: { type: String }
    },
    patients: { type: Array },
    apiResultPostalCode: { type: Object, default: () => null },
    usedMailMessage: { type: String },
    existsMedicalRecordInfo: { type: Array }
  },

  computed: {
    ...mapGetters({
      reservations: 'reservation/getReservations',
      reservationRequests: 'reservationRequests/getData',
      species: 'species/getDataIncludeDelData',
      prefecturesZero: 'master/selectPrefecturesZero',
      clinic: 'clinic/getClinic'
    }),
    attentionMessage() {
      return this.clinic.karteFlg
        ? this.$t('parts.noteAboutPatientWithKarte')
        : this.$t('parts.noteAboutPatientWithReservation')
    },
    isIncludesPatientIdInReservations: function() {
      return function(patient) {
        return this.reservations.some(v => v.patientId === patient.id) ||
          this.reservationRequests.some(
            v => v.patientId === patient.id && v.reservationId === 0
          )
          ? true
          : false
      }
    },
    prefecturesSelect() {
      return this.$i18n.locale === 'en'
        ? this.prefecturesZero.map(v => {
            return { ...v, name: v.nameEnglish || v.name }
          })
        : this.prefecturesZero
    }
  },

  methods: {
    addPatient() {
      this.$emit('add-patient')
    },
    delPatient(obj) {
      this.$emit('del-patient', obj)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-table-change-owner {
  width: 100%;
  margin: 0 auto;
  > table {
    margin: 0 auto;
    width: 960px;
    height: 27px;
    @include font-size(18);
    font-weight: bold;
    line-height: 1.5;
    text-align: left;
    border-spacing: 0px 20px;
    > tr {
      > th {
        width: 40%;
        &.patient-th {
          vertical-align: top;
          > .label {
            line-height: 45px;
            margin-right: 10px;
          }
          > .attention {
            margin-right: 30px;
            font-size: 15px;
            color: #{$plus_red};
          }
          > .attention-under-960-width {
            display: none;
          }
        }
      }
      > td {
        width: 60%;
        @include validate-message();
      }
    }
  }
}
@media (max-width: $global-media-width) {
  .input-table-change-owner {
    width: 70%;
    margin: 0 auto;
    > table {
      width: 95%;
      > tr {
        > th,
        td {
          width: 100%;
          display: block;
          margin-bottom: 15px;
          &.patient-th {
            > .label {
              line-height: 1.5;
            }
            > .attention {
              display: none;
            }
            > .attention-under-960-width {
              display: block;
              font-size: 15px;
              color: #{$plus_red};
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $global-container) {
  .input-table-change-owner {
    margin: 0 auto;
  }
}
</style>
