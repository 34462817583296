import { render, staticRenderFns } from "./InputTableChangeOwner.vue?vue&type=template&id=2f03b12c&scoped=true"
import script from "./InputTableChangeOwner.vue?vue&type=script&lang=js"
export * from "./InputTableChangeOwner.vue?vue&type=script&lang=js"
import style0 from "./InputTableChangeOwner.vue?vue&type=style&index=0&id=2f03b12c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f03b12c",
  null
  
)

export default component.exports